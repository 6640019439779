import React, { useState, useEffect } from 'react';

import { graphql } from 'gatsby';
import PropTypes from 'prop-types';

import { useIntl } from '../../../../plugins/publicis-gatsby-plugin-i18n';
import MediaPageHeaderDesign from '../../../assets/images/mediapage-design-header.inline.svg';
import Breadcrumb from '../../../components/Breadcrumb/Breadcrumb';
import Dropdown from '../../../components/Dropdown/Dropdown';
import PageInnerNav from '../../../components/PageInnerNav/PageInnerNav';
import Seo from '../../../components/Seo/Seo';
import Layout from '../../../Layout';
import { Button } from '../../../Molecules/Button/Button';
import FiltersTag from '../../../Molecules/FiltersTag/FiltersTag';
import { TitlePage } from '../../../Molecules/TitlePage/TitlePage';
import { getBreadCrumb, formatBytes } from '../../../Utils/Utils';
import useMedias from '../../../hooks/useMedias';
import ArticleSingle from '../../../components/ArticleSingle/ArticleSingle';
import TitleBlock from '../../../Molecules/TitleBlock/TitleBlock';
import DocumentView from '../../../Molecules/DocumentView/DocumentView';

import './styles.scss';

const classNames = require('classnames');

const MediaPage = ({ data }) => {
  const intl = useIntl();
  const { processUrl, getImage, getDocument } = useMedias();

  // const navigation = [
  //   {
  //     id: 'publications',
  //     drupalTID: 8,
  //     name: intl.formatMessage({ id: 'media.categories.publications' }),
  //     url: `/${intl.locale}/publications`,
  //   }
  // ];

  const articles = data.articles.edges.map((a) => a.node);
  const mediaPageData = data.media;
  const imageArray = data.allImages.edges;
  const showMoreVal = 5;
  const documentsArray = data.allDocuments.edges;
  const allMenu = data.allMenu.edges;
  const publicationCat = data.publicationCat?.edges;
  const breadCrumb = getBreadCrumb(
    allMenu,
    mediaPageData.path?.alias !== null &&
      mediaPageData.path?.alias !== undefined
      ? mediaPageData.path?.alias
      : ''
  );

  const [filteredArticle, setFilteredArticle] = useState(articles);
  const [filter_year, setFilteryear] = useState();
  const [filter_tag, setFilterTag] = useState();
  const [showItem, setShowItem] = useState(showMoreVal);
  const [yearList, setYearList] = useState([]);
  const [filters, setFilter] = useState([]);
  const metaTags =  mediaPageData.metatag;
  let metaTitle = '';
  let metaDesc = '';
  
  metaTags.forEach((meta) => {
    if(meta.attributes.name === 'title'){
        metaTitle = meta.attributes.content;
    }
    if(meta.attributes.name === 'description'){
        metaDesc = meta.attributes.content;
    }
  });
  let myYear = [];

  const handleFilterYearChange = (year) => {
    setFilteryear(year);
  };

  const handlefilterTagChange = (tag) => {
    setFilterTag(tag);
  };

  const onClickShowMore = () => {
    if (showItem < filteredArticle?.length) {
      setShowItem(showItem + showMoreVal);
    }
  };

  const setdropDownList = () => {
    let i = 0;

    articles?.forEach((article) => {
      if (
        myYear.filter((a) => a.label === article?.fields?.publicationYear)
          .length <= 0
      ) {
        myYear.push({
          id: i++,
          label: article?.fields?.publicationYear,
        });
      }
    });

    setYearList(myYear);
    setFilteryear(myYear[0]);
  };

  const publicationCatInit = () => {
    let pubCatArr = [];
    publicationCat.forEach(
      function (e) {
        pubCatArr.push(
          {
            id: 'pub_cat_' + e.node?.drupal_internal__tid,
            drupalTID: e.node?.drupal_internal__tid,
            name: e.node?.name,
          }
        )
      }
    )

    return pubCatArr;
  }

  useEffect(() => {
    setdropDownList();
    setFilteryear(myYear[0]);
    setFilter(publicationCatInit());
  }, []);

  // Effect: Filter
  useEffect(() => {
    const processfilter = () => {
      let updatedArticles = articles;

      if (
        filter_year) {
        updatedArticles = updatedArticles.filter(
          (a) => a.fields.publicationYear == filter_year.label
        ); // Actualy compare string between int!
      }
      if (filter_tag) {
        updatedArticles = updatedArticles.filter((a) =>
          a.relationships.field_tags.some(
            (t) => t.drupal_internal__tid === filter_tag.drupalTID
          )
        );


      }

      setFilteredArticle(updatedArticles);
    };

    processfilter();
  }, [filter_tag, filter_year]);


  return (
    <Layout>
      <Seo
        title={mediaPageData?.field_metatag?.title ? mediaPageData?.field_metatag?.title : metaTitle}
        description={mediaPageData?.field_metatag?.description ? mediaPageData?.field_metatag?.description : metaDesc}
      />
      <div className={classNames('page_template', 'page_media')}>
        <MediaPageHeaderDesign className="media_top_design" />
        <div className="wrapper_page">
          <Breadcrumb
            page_banner={false}
            data={{
              parentPage: {
                title: breadCrumb?.parent?.name,
                url: breadCrumb?.parent?.link,
              },
              currentPage: {
                title: breadCrumb?.current?.name,
                url: breadCrumb?.current?.link,
              },
              locale: mediaPageData.langcode,
            }}
          />
          <TitlePage color="color_dark_bleu" title={mediaPageData?.title} />
          <div className="filters">
            <div className="flex_line">
              <Dropdown
                customClass="dropdown-filter"
                list={yearList}
                selected={filter_year}
                onChange={handleFilterYearChange}
              />
            </div>
            <FiltersTag
              tags={filters}
              currentTag={filter_tag}
              customClass="only_mobile_tablet"
              onFilterChange={handlefilterTagChange}
            />

          </div>

          <div className="articles_container">
            <div className="list_articles" aria-live="polite">
              {filteredArticle && filteredArticle?.length === 0 && (
                <div className="zero_search_result">
                  <p>{intl.formatMessage({ id: 'search.zeroResult' })}</p>
                </div>
              )}

              <ul>
                {filteredArticle &&
                  filteredArticle.map((article, i) => (
                    <li
                      key={i}
                      className={classNames(
                        i < showItem ? 'show-item' : 'hide-item'
                      )}
                    >
                      {i < showItem && (
                        <ArticleSingle
                          title={article.title}
                          summary={article.body?.summary}
                          linkTo={
                            processUrl(
                              getDocument(
                                documentsArray,
                                article.relationships?.field_media_pdf
                                  ?.drupal_internal__mid
                              )?.uri?.url
                            )
                          }
                          xhtml={
                            processUrl(
                              getDocument(
                                documentsArray,
                                article.relationships?.field_media_xhtml
                                  ?.drupal_internal__mid
                              )?.uri?.url
                            )

                          }
                          image={getImage(
                            imageArray,
                            article.relationships?.field_image
                              ?.drupal_internal__mid
                          )}
                        />
                      )}
                    </li>
                  ))}
              </ul>
              <div className="btn_container_desktop">
                <Button
                  label={intl.formatMessage({ id: 'common.showMore' })}
                  icon="plus"
                  onClick={onClickShowMore}
                  customClass={
                    showItem >= filteredArticle?.length ? 'hide' : ''
                  }
                  aria-label={intl.formatMessage({ id: 'common.showMore' })}
                />
              </div>
            </div>
            <div className="btn_container_mobile">
              <Button
                label={intl.formatMessage({ id: 'common.showMore' })}
                icon="plus"
                onClick={onClickShowMore}
                customClass={showItem >= filteredArticle?.length ? 'hide' : ''}
                aria-label={intl.formatMessage({ id: 'common.showMore' })}
              />
            </div>
            <div className="col_2_items">
              <FiltersTag
                tags={filters}
                currentTag={filter_tag}
                customClass="only_desktop"
                onFilterChange={handlefilterTagChange}
              />
              {
                mediaPageData?.relationships?.field_blocs?.map((doc, i) => {
                  let docArr = [];
                  doc?.relationships?.field_file?.forEach((file) => {
                    docArr.push({
                      name: getDocument(documentsArray, file?.drupal_internal__mid)
                        ?.description,
                      size: `(.pdf ${formatBytes(
                        getDocument(documentsArray, file?.drupal_internal__mid)
                          ?.filesize
                      )})`,
                      link: processUrl(
                        getDocument(documentsArray, file?.drupal_internal__mid)?.uri?.url
                      ),
                    });

                  });
                  return (
                    <>
                      {
                        doc.field_title?.processed &&
                        <TitleBlock
                          title={doc.field_title?.processed}
                          color='color_yellow'
                        />
                      }
                      {
                        docArr.length > 0 &&
                        <DocumentView
                          data={docArr}
                        />
                      }
                    </>
                  );
                })
              }
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

MediaPage.propTypes = {
  data: PropTypes.object.isRequired,
  pageContext: PropTypes.object.isRequired,
};

export const query = graphql`
  query MediaTemplateQuery(
    $locale: String!
    $slug: String!
    $imagesID: [Int!]
    $documentsID: [Int!]
  ) {
    media: nodePage(langcode: { eq: $locale }, path: { alias: { eq: $slug } }) {
      langcode
      path {
        alias
        langcode
      }
      field_metatag {
        description
        title
      }
      metatag {
        attributes {
          content
          name
        }
      }
      title
      relationships {
        field_blocs {
          __typename
          ... on Node {
            ... on block_content__block_document {
              field_title {
                processed
              }
              relationships {
                field_file {
                  drupal_internal__mid
                }
              }
            }
          }
        }
      }
    }
    articles: allNodeArticle(
      filter: { langcode: { eq: $locale } }
      sort: { order: DESC, fields: created }
    ) {
      edges {
        node {
          langcode
          path {
            alias
          }
          created
          title
          relationships {
            field_tags {
              drupal_internal__tid
            }
            field_image {
              drupal_internal__mid
            }
            field_media_pdf {
              drupal_internal__mid
            }
          }
          fields {
            publicationYear
          }
        }
      }
    }
    allImages: allMediaImage(
      filter: {
        langcode: { eq: "fr" }
        drupal_internal__mid: { in: $imagesID }
      }
    ) {
      edges {
        node {
          drupal_internal__mid
          relationships {
            field_media_image {
              uri {
                url
              }
            }
          }
        }
      }
    }
    allDocuments: allMediaDocument(
      filter: {
        langcode: { eq: "fr" }
        drupal_internal__mid: { in: $documentsID }
      }
    ) {
      edges {
        node {
          langcode
          field_media_document {
            description
          }
          relationships {
            field_media_document {
              filesize
              filename
              filemime
              uri {
                url
              }
            }
          }
          drupal_internal__mid
        }
      }
    }
    publicationCat : allTaxonomyTermCategorieMedias(filter: {langcode: { eq: $locale }}) {
      edges {
        node {
          name
          drupal_internal__tid
          
        }
      }
    }
    allMenu: allMenuLinkContentMenuLinkContent(
      filter: { langcode: { eq: $locale } }
    ) {
      edges {
        node {
          drupal_parent_menu_item
          drupal_id
          langcode
          link {
            url
          }
          menu_name
          title
        }
      }
    }
  }
`;

export default MediaPage;
