import React from 'react';

import { Link } from 'gatsby';
import PropTypes from 'prop-types';

import {
  HorizontalYellowLineShape,
} from '../../Atoms/Icons/Icons';
import useMedias from '../../hooks/useMedias';
import DocumentView from '../../Molecules/DocumentView/DocumentView';
import SocialShare from '../../Molecules/SocialShare/SocialShare';

import './ArticleSingle.scss';

const classNames = require('classnames');

const ArticleSingle = ({
  title,
  linkTo,
  xhtml,
  image,
  customClass,
}) => {
  const { processUrl } = useMedias();

  return (
    <div className={classNames('article_single', customClass)}>
      <div className={classNames('visuel', { no_visual: !image })}>
        <a href={linkTo ? linkTo : '#'} target="_blank" rel="noreferrer">
          {image && (
            <img
              src={processUrl(image.uri.url)}
              alt={title}
              style={{ height: '100%', width: '100%', objectFit: 'cover' }}
            />
          )}
        </a>
      </div>
      <div className={classNames('text', 'no_summary')}>
        <div className={classNames('title_description', 'show_document_icon')}
        >
          <h2>
            <a
              href={linkTo ? linkTo : '#'}
              target={linkTo ? '_blank' : '_self'}
              rel="noreferrer"
            >
              {title}
            </a>
          </h2>
          {linkTo && (
            <DocumentView
              data={[
                {
                  name: title,
                  size: null,
                  link: linkTo,
                  xhtml_link: xhtml,
                  showName: false,
                  uniqueKey: 'article_',
                },
              ]}
            />
          )}
        </div>
      </div>
    </div>
  );
};

ArticleSingle.propTypes = {
  title: PropTypes.string.isRequired,
  summary: PropTypes.string,
  linkTo: PropTypes.string,
  image: PropTypes.object,
};

export default ArticleSingle;
