import React, { useState, useEffect } from 'react';

import { IconDeisnYellowRoundedHalf } from '../../Atoms/Icons/Icons';

import './PageInnerNav.scss';

let classNames = require('classnames');

const PageInnerNav = ({ nav, onFilterChange, customClass, currentNavFromParent }) => {
  const [currentNav, setCurrentNav] = useState(currentNavFromParent?.id);
  useEffect(() => {
    setCurrentNav(currentNavFromParent?.id == null || currentNavFromParent?.id == undefined ? 'all' : currentNavFromParent?.id);
  }, [currentNavFromParent]);

  return (
    <div className={classNames('page_inner_nav', customClass)}>
      <nav>
        <ul>
          {nav.map((nav_item, i) => (
            <li key={i}>
              <a href={nav_item.url} className={classNames({ 'is-active': currentNav === nav_item.id })} {...currentNav === nav_item.id && { 'aria-current': 'page' }}>
                {nav_item.name}
                <IconDeisnYellowRoundedHalf />
              </a>
              {/* onClick={(event) => handleClickNav(event, nav_item)} */}
            </li>
          ))}
        </ul>
      </nav>
    </div >
  );
};

export default PageInnerNav;
